/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState,useEffect,useMemo} from 'react';
import { useUser } from '../../Context/User';
import { REQUEST } from '../../Utils/Constants';
const RestorePsw = React.createContext();
export const RestorePswProvider = (props)=>{
    //const {} = props;
    const {user} = useUser();
    const [configData,setConfigData] = useState(null);
    const [configLoad,setConfigLoad] = useState(false);
    const [configError,setConfigError] = useState(null);
    const handleToggleLoad = (arg)=>setConfigLoad(arg);
    const refreshMyConfig = async()=>{
        let r = await REQUEST({
            url:"",
            postData:{
                type:"getConfigData",
            },
            handleToggleLoad
        })
        if(r.error){
            setConfigError(r.error);
        }else{
            setConfigData(r.result);
        }
    }
    useEffect(()=>{
        if(!configData&&!configLoad&&user)refreshMyConfig();
    },[configData,user])
    const value = useMemo(()=>{
        return({
            configData,
            configLoad,
            configError,
            refreshMyConfig
        })
    },[
        configData,
        configLoad,
        configError
    ])
    return <RestorePsw.Provider value={value} {...props}/>
}
export const useRestorePsw = ()=> React.useContext(RestorePsw);