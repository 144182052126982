
const FRONTEND = {
    LOCAL: "http://localhost:8082/",
    REMOTE: "https://duermase.net/",
    REMOTE_DEV: "https://develop.duermase.net/"
}   
const DEV = process.env.REACT_APP_DEV ? process.env.REACT_APP_DEV.trim() === "true" : false;
const LOCAL = process.env.REACT_APP_LOCAL ? process.env.REACT_APP_LOCAL.trim() === "true" : false;
export const GetFrontend = ()=>{
    if(DEV&&!LOCAL){
        return FRONTEND.REMOTE_DEV;
    }else if(DEV&&LOCAL){
        return FRONTEND.LOCAL;
    }else{
        return FRONTEND.REMOTE;
    }
}
