/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { REQUEST } from '../../../Utils/Constants';

const HomeContext = React.createContext();

export const HomeContextProvider = (props) => {
  // HOME STATES
  const [HomeData, setHomeData] = useState(null);
  const [HomeLoader, setHomeLoader] = useState(false);
  const [HomeError, setHomeError] = useState(null);
  const HomeHandleLoad = (arg) => setHomeLoader(arg);
  const [search, setSearch] = useState("");
  const [events, setEvents] = useState([]);

  // HEADER STATES
  const [HeaderText, UpdateHeaderText] = useState("Home");

  // HOME METHODS
  const SearchHomeData = async () => {
    let r = await REQUEST({
      url: "api/v1/GetHomeData",
      postData: null,
      handleToggleLoad: HomeHandleLoad
    });
    if (r.error) {
      setHomeError(r.error);
    }
    if (r.result.HomeData) {
      setHomeData(r.result.HomeData);
      // console.log("Se seteo HomeData")
    }
  };

  const getEvents = () => {
    if (HomeData && HomeData.events.length > 0) {
      return HomeData.events;
    } else {
      return [];
    }
  };

  // Se lanza la carga de datos sólo una vez, si no hay HomeData ni HomeError
  useEffect(() => {
    if (!HomeData && !HomeError) {
      (async () => {
        await SearchHomeData();
      })();
    }
  }, []);

  // Actualiza la lista de eventos al cambiar `search` o `HomeData`
  useEffect(() => {
    setEvents(getEvents());
  }, [search, HomeData]);

  const value = useMemo(() => {
    return {
      HomeData,
      HomeLoader,
      HomeError,
      HeaderText,
      search,
      setSearch,
      HomeHandleLoad,
      SearchHomeData,
      UpdateHeaderText,
      getEvents,
      events
    };
  }, [
    HomeData,
    HomeLoader,
    HomeError,
    HeaderText,
    search,
    events
  ]);

  return <HomeContext.Provider value={value} {...props} />;
};

export const useHomeContext = () => useContext(HomeContext);
