import React from "react";
import {Modal} from "react-bootstrap";
import {colors} from "../../../Utils/Constants";
import "./style.css";
import { Typography } from "@mui/material";

const ModalXl = (props)=>{
  const {title,show,handleClose,Content,size} = props;
  return(
    <>
    <Modal size={(size)?size:null} show={show} onHide={handleClose} aria-labelledby={"modal"+title} centered={true}>
      <Modal.Body style={{backgroundColor:colors.bgModals}}>
        <Modal.Title>
          <Typography style={{color:"#fff",textAlign:"center",fontSize:18}}>
            {title}
          </Typography>
        </Modal.Title>
        {
          (Content)?
          <div style={{padding:10,borderRadius:10}}>
            <Content {...props} />
          </div>
          :
          <div className="d-flex justify-content-center">
            <p style={{fontWeight:"bold",fontSize:20}}>
              El modal no tiene contenido
            </p>
          </div>
        }
      </Modal.Body>
    </Modal>
    </>
  )
}

export default ModalXl;
