import React,{Fragment} from "react";
import DateListEvent from "./DateListEvent";
import {useUser} from "../../../../Context/User";
import {FaHandPointUp} from "react-icons/fa";
import { colors } from "../../../../Utils/Constants";
import SeatSelector from "./SeatsSelector";
import LoginComponent from "../../LoginComponent";
import AreaSelector from "./AreaSelector";
import PriceSelector from "./PriceSelector";
import ButtonSearch from "./ButtonSearch";
import TicketsController from "./TicketsController"


const EventController = (props)=>{
    const {user,selectedDate,seats,selectedArea,tickets,selectedPrice,ModalFunctionsRef} = useUser();
    const errorTagStyle = {
        padding:20,
        borderRadius:15,
        borderStyle:"solid",
        borderWidth:2,
        borderColor:colors.bgGrayDark,
        width:"100%",
        marginTop:10
    }
    return(
        <>
            <DateListEvent />
            {
                selectedDate ? 
                <>
                    <SeatSelector />
                    {
                        seats === 0 ? 
                        <div style={errorTagStyle} className="d-flex justify-content-center">
                            <div style={{display:"flex",flexDirection:"row",maxWidth:300}}>
                                <div style={{width:"30%"}} className="alineado">
                                    {
                                        <FaHandPointUp style={{fontSize:60,color:colors.white}} />
                                    }
                                </div>
                                <div style={{width:"70%",textAlign:"center"}}>
                                    <span style={{fontWeight:"bold",fontSize:20,color:"dimgrey"}} className="alineado">
                                        ¿Cuantos asientos necesitas?
                                    </span>
                                </div>
                            </div>
                        </div> : null
                    }
                </> : null

            }
            {
                seats ? 
                <Fragment>
                    <AreaSelector />
                    {
                        selectedArea ? <Fragment>
                            {
                                selectedPrice ? <Fragment>
                                <PriceSelector />
                                {
                                    user&&user!=="Invitado"  ? 
                                    <Fragment>
                                        <ButtonSearch ref={ModalFunctionsRef} />
                                        {
                                            selectedPrice&&tickets.length>0 ? <TicketsController /> : null
                                        }
                                    </Fragment> : 
                                    <LoginComponent rightPanel />
                                }
                            </Fragment> : <PriceSelector />
                            }
                        </Fragment> : null
                    }
                </Fragment> : null
            }

        </>
    )
}

export default EventController;