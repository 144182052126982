import React from "react";
import {PriceSelectorProvider,usePriceSelector} from "./Context";
import {useUser} from "../../../../../Context/User";
import {Fade} from "@mui/material";
import Content from "./Content";
import AloneLoader from "../../../AloneLoader";

const PriceSelector = ()=>{
    const {selectedArea} = useUser();

    return(
        <>
            <Fade in={true} timeout={800}>
                <div>
                    <PriceSelectorProvider id_clave={selectedArea}>
                        <PriceSelectorTunnel Content={()=><Content />} />
                    </PriceSelectorProvider>
                </div>
            </Fade>
        </>
    )
}
export default PriceSelector;

const PriceSelectorTunnel = (props)=>{
    const {Content} = props;
    const {isLoading} = usePriceSelector();
    return(
        <>
            {
                isLoading ? <AloneLoader isVisible={true} size={100} /> : <Content />
            }
        </>
    )
}