import React,{Fragment} from "react";
import ListHorizontal from "./ListHorizontal";
import { colors } from "../../../../../Utils/Constants";
import {FaRegSadCry,FaHandPointUp} from "react-icons/fa";
import { useDateList } from "./Context";
import {useUser} from "../../../../../Context/User";

const Content = ()=>{
    const {functions} = useDateList();
    const {selectedDate} = useUser();
    const errorTagStyle = {
        padding:20,
        borderRadius:15,
        borderStyle:"solid",
        borderWidth:2,
        borderColor:colors.bgGrayDark,
        width:"100%"
    }
    return(
        <Fragment>

            {
                functions.length>0? 
                <Fragment>
                    <div className="d-flex justify-content-center">
                        <span style={{color:colors.white,fontWeight:"bold",fontSize:15}}>
                            Seleccionar función
                        </span>  
                    </div>
                    <ListHorizontal />
                    {
                        !selectedDate ? 
                            <Fragment>
                                <div style={errorTagStyle} className="d-flex justify-content-center">
                                    <div style={{display:"flex",flexDirection:"row",maxWidth:300}}>
                                        <div style={{width:"30%"}} className="alineado">
                                            {
                                                <FaHandPointUp style={{fontSize:60,color:colors.white}} />
                                            }
                                        </div>
                                        <div style={{width:"70%",textAlign:"center"}}>
                                            <span style={{fontWeight:"bold",fontSize:20,color:"dimgrey"}} className="alineado">
                                                ¿En que horario te parece mejor?
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Fragment> : null 
                    }
                    {
                        /*
                        selectedFunction.img_areas ? <Fragment>
                            <div className="d-flex justify-content-center" style={{marginTop:10}}>
                                <IconButton type="info" execute={()=>handleToggleModal(true)} title="Ver áreas del teatro" icon="FaMapMarked" round/>
                            </div>
                            <Modal size={"md"}
                                title={"Mapa de áreas "+selectedFunction.teatro}
                                visible={modalArea}
                                handleHide={()=>handleToggleModal(false)}
                                Content={()=>
                                    <Fragment>
                                        <div className="d-flex justify-content-center p-2">
                                            <img src={selectedFunction.img_areas} style={{width:300,height:300,borderRadius:20}} />
                                        </div>
                                    </Fragment>
                                }
                                url={null}
                                postData={{}}
                            />
                        </Fragment> : null
        */
                    }
                </Fragment> : 
                <div style={errorTagStyle} className="d-flex justify-content-center">
                    <div style={{display:"flex",flexDirection:"row",maxWidth:300}}>
                        <div style={{width:"30%"}} className="alineado">
                            {
                                <FaRegSadCry style={{fontSize:60,color:colors.secondary}} />
                            }
                        </div>
                        <div style={{width:"70%",textAlign:"center"}}>
                            <span style={{fontWeight:"bold",fontSize:20,color:"dimgrey"}} className="alineado">
                                Parece que no hay funciones disponibles para este evento
                            </span>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default Content;