import React from "react";
import ContextComponent from "../../../Context/Component";
import Content from "./BodyContent";
import { Fade } from "@mui/material";
const Component = (props)=>{
    const principalBodyStyle={
        maxHeight:400,
        overflowY:"auto",
    }
    return(
        <>
            <div style={principalBodyStyle}>
                <Fade in={true} timeout={800}>
                    <div>
                        <ContextComponent Content={()=><Content />} url="" data_start={{type:"getEvents"}} />
                    </div>
                </Fade>
            </div>
        </>
    )
}

export default Component;