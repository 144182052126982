import React,{useState,Fragment} from "react";
import {Row,Col} from "react-bootstrap";
import { colors, isWeb, REQUEST } from "../../../../../Utils/Constants";
import {Toast} from "../../../../../Utils/Toast";
import {FaHandPointUp} from "react-icons/fa";
import { useTickets } from "./Context";
import {useUser} from "../../../../../Context/User";
import CardList from "./CardList"
import IconButton from "../../../IconButton";
import SeatsMap from "./SeatsMap";
import ModalConfirmation from "./ModalConfirmation/index";

const Content = ()=>{
    const {data,CVC,NUMBER,CARDNAME,EXPMONTH,EXPYEAR,DIR} = useTickets();
    const {paymethods,valor,functionDate,id_price,cargos,helpText,minantes,comments,currency_label,currency,API_TYPE} = data;
    const {selectedDate,tickets,user,selectedFunction,selectedArea,searchTickets,loadingTickets} = useUser();
    const [selectedPayMethod,setSelectedPayMethod] = useState(paymethods[0]||null);
    const [SAVEPAYMENT,setSAVEPAYMENT] = useState(false);
    const [loaderPayment,setLoaderPayment] = useState(false);
    const handleLoadPayment = (param)=>setLoaderPayment(param);
    const [pagado,setPagado] = useState(false);
    const showHelpText = ()=>{
        if(functionDate){
            let date_function = transformStrDate(functionDate);
            let now = new Date();
            date_function.setMinutes(date_function.getMinutes()-minantes);
            if(date_function<=now){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }
    const transformStrDate = (f)=>{
        let new_date = new Date();
        let f_year = parseInt(f.split("T")[0].split("-")[0]);
        let f_month = parseInt(f.split("T")[0].split("-")[1]);
        let f_day = parseInt(f.split("T")[0].split("-")[2]);
        let f_hour = parseInt(f.split("T")[1].split(":")[0]);
        let f_min = parseInt(f.split("T")[1].split(":")[1]);
        new_date.setYear(f_year);
        new_date.setMonth(f_month-1);
        new_date.setDate(f_day);
        new_date.setHours(f_hour);
        new_date.setMinutes(f_min);
        return new_date;
      }
    const NEXT = ()=>{
        let index = getPayMethodIndex();
        if(paymethods[index+1]){
            setSelectedPayMethod(paymethods[index+1])
        }
    }
    const PREV = ()=>{
        let index = getPayMethodIndex();
        if(paymethods[index-1]){
            setSelectedPayMethod(paymethods[index-1]);
        }
    }
    const getPayMethodIndex = ()=>{
        let index;
        for (let i = 0; i < paymethods.length; i++) {
            const element = paymethods[i];
            if(element.id_metodo===selectedPayMethod.id_metodo){
                index = i;
            }
        }
        return index;
    }
    const errorTagStyle = {
        padding:20,
        borderRadius:15,
        borderStyle:"solid",
        borderWidth:2,
        borderColor:colors.bgGrayDark,
        width:"100%"
    }
    const buyTickets = async(PREFERENCE_ID)=>{
        let r = await REQUEST({
            url:"",
            postData:{
                type:"paymentTickets",
                total:getTotal().total,
                user:user.id_usuario,
                tickets:tickets,
                payMethod:selectedPayMethod?selectedPayMethod.id_metodo:null,
                cvc:CVC,
                event:selectedFunction.id_clave,
                funcion:selectedDate,
                area:selectedArea,
                price:valor,
                id_price,
                functionDate,
                form:{
                    NUMBER,
                    CARDNAME,
                    EXPMONTH,
                    EXPYEAR,
                    SAVEPAYMENT
                },
                direccion:DIR,
                PREFERENCE_ID
            },
            handleToggleLoad:handleLoadPayment
          })
          if(r){
            if(r.error){
                Toast(r.error,"error");
            }else{
                Toast("Gracias por su compra.","info");
                setPagado(true)
                let param;
                if(PREFERENCE_ID){
                    param = PREFERENCE_ID;
                }else{
                    param = r.result.token;
                }
                setTimeout(()=>{
                    window.location.href = "/payment-proccess?id="+param;
                },1000)
            }
          }
    }
    const getTotal = (param)=>{
        let total = 0;
        let cargo = parseFloat(cargos);
        tickets.forEach(item => {
            if(item.estado===1){
                total += valor;
            }
        });
        let addStripeCost = checkEvents(selectedFunction.id_clave);
        let stripe = getStripeCost(total)
        let iva = getIVACost(stripe); 
        let subtotal = addStripeCost ? total : getSubtotalCost(stripe,total)
        if(addStripeCost){
            return {
                total:(Math.round(subtotal*100)/100),
                cargo:0,
                subtotal:(Math.round(subtotal*100)/100)
            }
        }else{
            return {
                total:(Math.round(subtotal*100)/100)+(cargo*tickets.length),
                cargo:(Math.round((stripe+iva)*100)/100)+(cargo*tickets.length),
                subtotal:total
            }
        }
    }
    const checkEvents = (ID)=>{
        let evnts = [51];
        let filtered = evnts.filter(item=>item===ID);
        if(filtered.length>0){
            return true;
        }else{
            return false;
        }
    }
    const getIVACost = (stripe)=>{
        switch (currency) {
            case "mxn":
                return ( stripe * 1.16 ) - stripe
            case "usd":
                return 0;
            default:
                return ( stripe * 1.16 ) - stripe
        }
    }
    const getSubtotalCost = (stripe,total)=>{
        let iva = getIVACost(stripe);
        switch (currency) {
            case "mxn":
                return stripe + iva + total;
            case "usd":
                return stripe + total;    
            default:
                return stripe + iva + total;
        }
    }
    const getStripeCost = (total)=>{
        let total_stripe = ( (total/100) * 3.6 );
        switch (currency) {
            case "mxn":
                total_stripe += total_stripe*.16;
                return ( total_stripe + 3);
            case "usd":
                total_stripe = ( (total/100) * 2.9 );
                return ( total_stripe + .3);    
            default:
                return ( total_stripe + 3);
        }
    }
    const checkFormErrors = ()=>{
        //console.log({API_TYPE});
        switch (API_TYPE) {
            case 1:
                /*if(NUMBER&&!isNaN(NUMBER)&&NUMBER.length === 16){
                    if(CARDNAME&&CARDNAME.length>10){
                        if(EXPMONTH){
                            if(EXPYEAR){
                                if(CVC&&!isNaN(CVC)){
                                    if(DIR){
                                        return true
                                    }else{
                                        return false
                                    }
                                }else{
                                    return false
                                }
                            }else{
                                return false
                            }
                        }else{
                            return false
                        }
                    }else{
                        return false
                    }
                }*/
                return true;
            default:
                return true;
        }
        
    }
    const rtnStringTickets = (t)=>{
        let string = "";
        for (let i = 0; i < t.length; i++) {
            const item = t[i];
            if(item.estado===1&&i<2){
                string += item.butaca;
                if(i!==t.length-1){
                    string += "-"
                }
            }
        }
        if(tickets.length>=3){
            string += "...";
        }
        return string;
    }
    const rtnStringDate = ()=>{
        let months = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
        let arrayDate = functionDate.split("T");
        let date = arrayDate[0];
        let hours = arrayDate[1].split("Z")[0];
        let hour = hours.split(":")[0];
        let minutes = hours.split(":")[1];
        let year = date.split("-")[0];
        let month = parseInt(date.split("-")[1])-1;
        let day = date.split("-")[2];
        return ` ${day} de ${months[month]} del ${year} a las ${hour}:${minutes}`;
    }
    const returnFilas = (t)=>{
        let newFilas = [];
        for (let i = 0; i < t.length; i++) {
            const item = t[i];
            if(i===0){
                newFilas.push(item.rowName);
            }else{
                let check = newFilas.filter(el=>el===item.rowName);
                if(check.length===0){
                    newFilas.push(item.rowName);
                }
            }
        }
        let newFilas2 = newFilas.filter((el,i)=>i<=2);
        let str = "";
        newFilas2.forEach((item,index)=>{
            str += item;
            if(index!==newFilas2.length-1){
                str += "-"
            }
        })
        let points = newFilas.length >= 3 ? "..." : "";
        return str + points ;
    }        
    return(
        <Fragment>
            <div className="d-flex justify-content-center">
                <span style={{color:colors.white,fontWeight:"bold",fontSize:15}}>
                    Mejores asientos encontrados
                </span>  
            </div>
            <div className="d-flex justify-content-center">
                <div style={{borderStyle:"solid",borderColor:colors.primary,borderWidth:2,borderRadius:5,marginTop:10,marginBottom:10}}>
                    <div className="d-flex justify-content-center">
                        <span style={{color:colors.primary,fontWeight:"bold",fontSize:15,marginTop:5}}>
                            ESCENARIO
                        </span>  
                    </div>
                    <SeatsMap />
                    <Row style={{marginBottom:10}}>
                        <Col xs="12" md="6">
                            <div className="d-flex justify-content-center">
                                <div style={{display:"flex",flexDirection:"row"}}>
                                    <div style={{width:15,height:15,backgroundColor:colors.secondary,marginTop:3,marginRight:5}}></div>
                                    <div className="d-flex justify-content-center">
                                        <span style={{color:colors.secondary,fontWeight:"bold",fontSize:15}}>
                                            Seleccionados
                                        </span>  
                                    </div>
                                </div>  
                            </div>
                        </Col>
                        <Col xs="12" md="6">
                            <div className="d-flex justify-content-center">
                                <div style={{display:"flex",flexDirection:"row"}}>
                                    <div style={{width:15,height:15,backgroundColor:colors.bgGrayDark,marginTop:3,marginRight:5}}></div>
                                    <div className="d-flex justify-content-center">
                                        <span style={{color:colors.secondary,fontWeight:"bold",fontSize:15}}>
                                            No seleccionables
                                        </span>  
                                    </div>
                                </div>  
                            </div>
                        </Col>
                    </Row>
                    {
                        comments.cm9?
                        <div className="d-flex justify-content-center"> 
                                <span style={{color:colors.primary,fontWeight:"bold",fontSize:15,marginTop:5,marginBottom:5}}>
                                    {comments.cm9}
                                </span>  
                            </div>
                        : null
                    }
                    {
                        comments.cm10?
                        <div className="d-flex justify-content-center"> 
                                <span style={{color:colors.primary,fontWeight:"bold",fontSize:15,marginTop:5,marginBottom:5}}>
                                    {comments.cm10}
                                </span>  
                            </div>
                        : null
                    }
                    {
                        showHelpText() ? 
                            <div className="d-flex justify-content-center">
                                <span style={{color:colors.primary,fontWeight:"bold",fontSize:15,marginTop:5,marginBottom:5}}>
                                    Nota: {helpText}
                                </span>  
                            </div>
                        : null
                    }
                    <div className="d-flex justify-content-center" style={{marginBottom:10}}>
                        <IconButton icon="FaSyncAlt" type="info" loader={loadingTickets} execute={searchTickets} title="Volver a buscar" round/>
                    </div>
                </div>
            </div>
            {
                !pagado ? 
                <Fragment>
                    <div className="d-flex justify-content-center">
                        <span style={{color:colors.white,fontWeight:"bold",fontSize:10}}>
                        Verifique sus asientos
                        </span>  
                    </div>
                    <CardList 
                        payMethod={selectedPayMethod} 
                        setPayMethod={setSelectedPayMethod}
                        SAVEPAYMENT={SAVEPAYMENT}
                        setSAVEPAYMENT={setSAVEPAYMENT}
                        NEXT={NEXT} 
                        PREV={PREV} 
                        pagado={pagado}
                    />
                    <div className="d-flex justify-content-center mt-2">
                        <span style={{color:colors.white,fontSize:15,fontWeight:"bold"}}>
                                    Subtotal: $ {`${getTotal().subtotal.toFixed(2)} ${currency_label}`}
                        </span>
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                        <span style={{color:colors.white,fontSize:15,fontWeight:"bold"}}>
                                    Cargo por servicio: $ {`${getTotal().cargo.toFixed(2)} ${currency_label}`}
                        </span>
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                        <span style={{color:colors.white,fontSize:15,fontWeight:"bold"}}>
                                    Total: $ {`${getTotal().total.toFixed(2)} ${currency_label}`}
                        </span>
                    </div>
                    {
                        checkFormErrors() ?
                            <Fragment>
                                <ModalConfirmation 
                                    buyTickets={buyTickets} 
                                    loaderPayment={loaderPayment} 
                                    total={getTotal().total} 
                                    price={valor}  
                                    event={selectedFunction.id_clave}
                                    area={selectedArea}  
                                />
                                <div className="d-flex justify-content-center mt-2">
                                    <span style={{color:colors.secondary,fontSize:10,fontWeight:"bold",maxWidth:isWeb()?500:300,textAlign:"justify"}}>
                                                NOTA: La compra y elección de asientos y localidad es responsabilidad únicamente suya, por favor revise y verifique la zona, fecha, hora y localidad de su compra ya que una vez hecha la compra NO hay reclamo, cambio o devolucion alguna. De no asistir en la fecha y hora elegida la empresa no se hace responsable de reclamaciones y/o devoluciones. 
                                    </span>
                                </div>
                            </Fragment> :
                            <Fragment>
                                <div style={errorTagStyle} className="d-flex justify-content-center mt-2">
                                    <div style={{display:"flex",flexDirection:"row",maxWidth:300}}>
                                        <div style={{width:"30%"}} className="alineado">
                                            {
                                                <FaHandPointUp style={{fontSize:60,color:colors.white}} />
                                            }
                                        </div>
                                        <div style={{width:"70%",textAlign:"center"}}>
                                            <span style={{fontWeight:"bold",fontSize:20,color:"dimgrey"}} className="alineado">
                                                Selecciona un método de pago o inserte los datos de su tarjeta
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                    }
                </Fragment> 
                 
                :
                    <Fragment>
                        <div style={{width:"100%",padding:20}}>
                            <div style={{borderRadius:15,backgroundColor:colors.secondary,padding:20}}>
                                <div style={{display:"flex",justifyContent:"center",alignItems:"centern"}}>
                                    <span style={{color:"#fff",fontSize:25,fontWeight:"bold"}}>
                                        GRACIAS POR SU COMPRA.
                                    </span>
                                </div>
                                <div style={{display:"flex",justifyContent:"center",alignItems:"centern"}}>
                                    <span style={{color:"#fff",fontSize:15,fontWeight:"bold",textAlign:"center"}}>
                                        Presentarse 30 minutos antes en taquilla con una identificación oficial o en la entrada de teatro con  la imagen del CODIGO QR que se encuentra en la sección DE MIS COMPRAS en la página donde realizó la compra de su boleto.
                                    </span>
                                </div>
                                <div style={{display:"flex",justifyContent:"center",alignItems:"centern"}}>
                                    <span style={{color:"#fff",fontSize:18,fontWeight:"bold"}}>
                                        Información de compra.
                                    </span>
                                </div>

                                <div style={{display:"flex",justifyContent:"center",alignItems:"centern",marginTop:10}}>
                                    <div style={{display:"flex",flexDirection:"row"}}>
                                        <div style={{display:"flex",justifyContent:"center",alignItems:"centern"}}>
                                            <span style={{color:"#fff",fontSize:15,fontWeight:"bold"}}>
                                                Asientos: 
                                            </span>
                                        </div>
                                        <div style={{display:"flex",justifyContent:"center",alignItems:"centern"}}>
                                            <span style={{color:"#fff",fontSize:15,fontWeight:"bold"}}>
                                                {rtnStringTickets(tickets)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:"flex",justifyContent:"center",alignItems:"centern",marginTop:10}}>
                                    <div style={{display:"flex",flexDirection:"row"}}>
                                        <div style={{display:"flex",justifyContent:"center",alignItems:"centern"}}>
                                            <span style={{color:"#fff",fontSize:15,fontWeight:"bold"}}>
                                                Fila: 
                                            </span>
                                        </div>
                                        <div style={{display:"flex",justifyContent:"center",alignItems:"centern"}}>
                                            <span style={{color:"#fff",fontSize:15,fontWeight:"bold"}}>
                                                {" "+returnFilas(tickets)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:"flex",justifyContent:"center",alignItems:"centern",marginTop:10}}>
                                    <div style={{display:"flex",flexDirection:"row"}}>
                                        <div style={{display:"flex",justifyContent:"center",alignItems:"centern"}}>
                                            <span style={{color:"#fff",fontSize:15,fontWeight:"bold"}}>
                                                Horario de función:
                                            </span>
                                        </div>
                                        <div style={{display:"flex",justifyContent:"center",alignItems:"centern"}}>
                                            <span style={{color:"#fff",fontSize:15,fontWeight:"bold"}}>
                                                {" "+rtnStringDate()}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </Fragment>
            }
            {
                !isWeb() ? <div style={{height:100}}></div> : null 
            }
            
        </Fragment>
    )
}
export default Content;