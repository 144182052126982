import React from "react";
import Content from "./Content";
import SCREEN from "../../Context/Screen";
import { useSearchParams } from "react-router-dom";
/*
id=5
&collection_id=1247128991
&collection_status=approved
&payment_id=1247128991
&status=approved
&external_reference=null
&payment_type=credit_card
&merchant_order_id=4420135189
&preference_id=729427506-ed223860-2b86-4967-bfb6-326dd68f8ba9
&site_id=MLM
&processing_mode=aggregator
&merchant_account_id=null
*/
const Page = ()=>{
    const [params] = useSearchParams();
    let URLPARAMS;
    if(params.get("id")){//mercado pago
        URLPARAMS = {
            id:params.get("id"),
            collection_id:params.get("collection_id"),
            payment_id:params.get("payment_id"),
            status:params.get("status"),
            external_reference:params.get("external_reference"),
            payment_type:params.get("payment_type"),
            merchant_order_id:params.get("merchant_order_id"),
            preference_id:params.get("preference_id"),
            site_id:params.get("site_id"),
            processing_mode:params.get("processing_mode"),
            merchant_account_id:params.get("merchant_account_id")
        }
    }else{//stripe
        URLPARAMS = {
            payment_intent:params.get("payment_intent")
        }
    }
    return(
        <SCREEN url="payment" post={{type:"payment-proccess",URLPARAMS}} SCREEN_NEED_USER>
            <Content />
        </SCREEN>
    )
}
export default Page;