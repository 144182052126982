import React from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import { Container } from "react-bootstrap";
import { colors } from "../../../../../../../Utils/Constants";
import { useConfirmation } from "../Store";
import { PKSTRIPE } from "../../../../../../../Utils/Constants";
const stripePromise = loadStripe(PKSTRIPE);
const StripeForm = ()=>{
    const {clientSecret} = useConfirmation();
    if(clientSecret){
        console.log("PKSTRIPE:",PKSTRIPE);
        const options = {
            // passing the client secret obtained from the server
            clientSecret,
        };
        return(
            <Container className="d-flex justify-content-center">
                <div style={{
                    width:"90%",
                    backgroundColor:colors.bgGrayDark,
                    padding:20,
                    marginTop:10,
                    border:`solid 1px ${colors.primary}`,
                    borderRadius:10
                }}>
                    <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm />
                    </Elements>
                </div>
            </Container>
        )
    }
    return null;
}
export default StripeForm;