import React from "react";
import {DateListProvider,useDateList} from "./Context";
import {useUser} from "../../../../../Context/User";
import Content from "./Content";
import AloneLoader from "../../../AloneLoader";
import { Fade } from "@mui/material";
const DateListEvent = (props)=>{
    const {selectedFunction} = useUser();

    if(selectedFunction)return(
        <>
            <Fade in={true} timeout={800}>
                <div>
                    <DateListProvider id_clave={selectedFunction.id_clave}>
                        <DateListTunnel Content={()=><Content />} />
                    </DateListProvider>
                </div>
            </Fade>
        </>
    );
    return null;
}
export default DateListEvent;

const DateListTunnel = (props)=>{
    const {Content} = props;
    const {isLoading} = useDateList();
    return(
        <>
            {
                isLoading ? <AloneLoader isVisible={true} size={100} /> : <Content />
            }
        </>
    )
}