import React from "react";
import {TicketsProvider,useTickets} from "./Context";
import {Fade} from "@mui/material";
import Content from "./Content";
import AloneLoader from "../../../AloneLoader";
import ErrorContainer from "../../../ErrorContainer";

const TicketsController = ()=>(
    <Fade in={true} timeout={800}>
        <div>
            <TicketsProvider>
                <TicketsTunnel Content={()=><Content />} />
            </TicketsProvider>
        </div>
    </Fade>
)
export default TicketsController;

const TicketsTunnel = (props)=>{
    const {Content} = props;
    const {isLoading,data,error} = useTickets();
    return(
        <>
            {
                isLoading ? <AloneLoader isVisible={true} size={100} /> : error? <ErrorContainer error={error} /> : data ? <Content /> : <p>...</p> 
            }
        </>
    )
}