import {Routes,Route} from "react-router-dom";
import {Fragment} from "react";
import Home from "../Screens/Home";
import PaymentProccess from "../Screens/PaymentProccess";
import FootBar from "../Components/Elements/Footbar";
import RestorePsw from "../Screens/RestorePsw";
import MyConfig from "../Screens/MyConfig";
import MyShopping from "../Screens/MyShopping";
import LoginPage from "../Screens/Login";
const NAVIGATION = ()=>{
    return(
        <Fragment>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/login" element={<LoginPage />} />
                <Route exact path="/MyConfig" element={<MyConfig />} />
                <Route exact path="/RestorePsw" element={<RestorePsw />} />
                <Route exact path="/MyShopping" element={<MyShopping />} />
                <Route exact path="/payment-proccess" element={<PaymentProccess />} />
            </Routes>
            <FootBar />
        </Fragment>
    )
}
export default NAVIGATION;