import React from "react";
import { colors, isWeb } from "../../../../../Utils/Constants";
import { useDateList } from "./Context";
import { useUser } from "../../../../../Context/User";

const ListHorizontal = (props)=>{
    const {functions} = useDateList();
    const {selectedDate,modifyDate} = useUser();
    const principalStyle = {
        width:"100%",
        maxHeight:180,
        paddingLeft:5,
        paddingRight:5,
        paddingTop:10,
        paddingBottom:10,
        overflowX:"scroll",
        alignItems:"center",
        display:"flex",
        flexDirection:"row",
        marginTop:20
    }
    const dateStyleContainer = {
        minWidth:200,
        borderStyle:"solid",
        borderWidth:2,
        borderRadius:10,
        marginLeft:10,
        cursor:"pointer",
        backgroundColor:""
    }
    const getStringDay = (f)=>{
        let f_edited = f.replace("T"," ").replace("Z","");
        f_edited = f_edited.replaceAll("-","/")
        if(f_edited.length>19){
            f_edited = f_edited.substring(0,(f_edited.length-4));
        }
        let newDate = new Date(f_edited);
        let newIndex = newDate.getDay();
        let days = ["Domingo","Lunes","Martes","Miercoles","Jueves","Viernes","Sabado"];
        return days[newIndex];
    }
    const getStringDate = (f)=>{
        let f_edited = f.replace("T"," ").replace("Z","");
        f_edited = f_edited.replaceAll("-","/")
        if(f_edited.length>19){
            f_edited = f_edited.substring(0,(f_edited.length-4));
        }
        let newDate = new Date(f_edited);
        let day = newDate.getDate();
        return day;
    }
    const getStringMonth = (f)=>{
        let f_edited = f.replace("T"," ").replace("Z","");
        f_edited = f_edited.replaceAll("-","/")
        if(f_edited.length>19){
            f_edited = f_edited.substring(0,(f_edited.length-4));
        }
        let newDate = new Date(f_edited);
        let index = newDate.getMonth();
        let months = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
        return months[index];
    }
    const getStringYear = (f)=>{
        let f_edited = f.replace("T"," ").replace("Z","");
        f_edited = f_edited.replaceAll("-","/")
        if(f_edited.length>19){
            f_edited = f_edited.substring(0,(f_edited.length-4));
        }
        let newDate = new Date(f_edited);
        let year = newDate.getFullYear();
        return year.toString();;
    }
    /*const getStringHour = (f)=>{
        let f_edited = f.split("T")[1].replace("Z","");
        f_edited = f_edited.split(":");
        let AM_PM = f_edited[0] >= 12 ? "PM" : "AM";
        return `${f_edited[0]} : ${f_edited[1]} ${AM_PM}`; 
    }*/
    return(
        <div style={principalStyle} className={isWeb()?"d-flex justify-content-center":""}>
                {
                    functions.map((item,index)=>(
                        <div key={index} onClick={()=>modifyDate(item.id_clave)} style={{...dateStyleContainer,backgroundColor:item.id_clave===selectedDate?colors.secondary:colors.bgDark}} className="minisizeable containerDate">
                            <div style={{padding:10}} className="alineado">
                                    <span className="text-center" style={{color:item.id_clave===selectedDate?colors.white:colors.white,fontWeight:"bold",fontSize:12}}>
                                        {getStringDay(item.fecha) + " " + getStringDate(item.fecha)}
                                    </span>
                                    <span className="text-center" style={{color:item.id_clave===selectedDate?colors.white:colors.white,fontWeight:"bold",fontSize:12}}>
                                        {" de " + getStringMonth(item.fecha)}
                                    </span>
                                    <span className="text-center" style={{color:item.id_clave===selectedDate?colors.white:colors.white,fontWeight:"bold",fontSize:12}}>
                                        {" del " + getStringYear(item.fecha)}
                                    </span>
                                    <span className="text-center" style={{color:item.id_clave===selectedDate?colors.white:colors.white,fontWeight:"bold",fontSize:12}}>
                                        {item.hora}
                                    </span>
                            </div>    
                        </div>
                    ))
                }
        </div>
    )
}
export default ListHorizontal; 