import React,{ Fragment } from "react";
import { colors,isWeb } from "../../../../../Utils/Constants";
import {useTickets} from "./Context";
import {Row,Col} from "react-bootstrap";
const SeatsMap = ()=>{
    const ES_WEB = isWeb();
    const {data} = useTickets();
    const {filas,areas_seats} = data;


    const principal_div = {
        width:"100%",
        paddingLeft:15,
        paddingRight:15,
        overflowY:"auto",
        overflowX:"auto",
        paddingTop:5
    }
    return(
        <Fragment>
           <div style={principal_div}>
                {
                    filas.map((fila,index_fila)=>(
                        <div key={index_fila} style={{width:"100%",display:"flex",flexDirection:"row",overflowX:"auto"}}>
                            {
                                fila.asientos.map((asiento,index_asiento)=>(
                                    <div className="minisizeable" key={index_asiento} style={{
                                            width:ES_WEB?15:5,
                                            height:ES_WEB?15:5,
                                            borderStyle:"solid",
                                            backgroundColor:asiento.color,
                                            borderColor:asiento.bdColor,
                                            marginLeft:ES_WEB?3:1,
                                            marginRight:ES_WEB?3:1,
                                            marginTop:ES_WEB?3:1,
                                            marginBottom:ES_WEB?3:1,
                                            cursor:"pointer",
                                            borderWidth:2}}>

                                        </div>
                                    
                                ))
                            }
                        </div>    
                    ))
                }
           </div>
            <Row style={{marginBottom:10}}>
                {
                    areas_seats.map((item,index)=>(
                        <Col key={index} xs="12" md="4">
                            <div className="d-flex justify-content-center">
                                <div style={{display:"flex",flexDirection:"row"}}>
                                    {
                                        item.nameArea ?
                                            <>
                                                <div style={{width:15,height:15,backgroundColor:item.color,borderStyle:"solid",borderWidth:2,marginTop:3,marginRight:5}}></div>
                                                <div className="d-flex justify-content-center">
                                                    <span style={{color:colors.secondary,fontWeight:"bold",fontSize:15}}>
                                                        {item.nameArea}
                                                    </span>  
                                                </div> 
                                            </> 
                                            : null
                                    }
                                </div>  
                            </div>
                        </Col>
                    ))
                }
            </Row>

        </Fragment>
    )
}

export default SeatsMap;