import React,{useState,Fragment} from "react";
import {useComponent} from "../../../Context/Component/Context";
import { FaTheaterMasks } from 'react-icons/fa';
import { colors } from "../../../Utils/Constants";
import Input from "../Input";
import {useUser} from "../../../Context/User";
import {FaRegSadCry} from "react-icons/fa";
const Component = (props)=>{
    const {selectedFunction,modifyFunction} = useUser();
    const {data} = useComponent();
    const {functions} = data;
    const [search,setSearch] = useState("");
    
    const fontStyle = {
        color:"#fff",
        fontSize:14
    }
    const containerDiv = {
        width:"100%",
        cursor:"pointer",
        marginTop:5,
        borderRadius:10,
        padding:2
    }
    const filteredFunctions = ()=>{
        let s = search.toLowerCase();
        let filteredValues = functions.filter(item=>( ( item.estado.toLowerCase().indexOf(s)!==-1 ) || ( item.ciudad.toLowerCase().indexOf(s)!==-1 ) ) );
        return filteredValues;
    }

    return(
        <Fragment>
            {
                functions&&functions.length>0 ? 
                <div style={{marginTop:10,padding:15,overflowX:"hidden"}}>
                        <Input value={search} onChange={setSearch} placeholder="Buscar por estado o ciudad" />
                    {
                        filteredFunctions().map((item,index)=>(
                            <div className="containerFunction minisizeable" onClick={()=>modifyFunction(item)} style={{...containerDiv,backgroundColor:selectedFunction&&selectedFunction.id_clave===item.id_clave?colors.secondary:colors.bgDark,marginTop:10,overflowX:"hidden"}} key={index}>
                                
                                <div style={{display:"flex",flexDirection:"row"}}>
                                    <div style={{width:"20%",textAlign:"center"}}>
                                    <FaTheaterMasks style={{fontSize:50,color:selectedFunction&&selectedFunction.id_clave===item.id_clave?"#fff":colors.secondary}} />
                                        {
                                            //item.img_teatro ?  <img src={item.img_teatro} style={{width:50,height:50,borderRadius:25}} /> : <FaTheaterMasks style={{fontSize:50,color:"#fff"}} />
                                        }
                                    </div>
                                    <div style={{width:"80%",paddingLeft:10}}>
                                        <div style={{textAlign:"left"}}>
                                            <span style={{...fontStyle,color:selectedFunction&&selectedFunction.id_clave===item.id_clave?"#fff":colors.secondary}}>Teatro:</span><br></br>
                                        </div>
                                        <div style={{textAlign:"center"}}>
                                        <span style={{fontWeight:"bold",...fontStyle,color:selectedFunction&&selectedFunction.id_clave===item.id_clave?"#fff":colors.secondary}}>{item.teatro}</span>
                                        </div>
                                    </div> 
                                </div>
                                <div style={{textAlign:"center"}}>
                                    <span style={{...fontStyle,fontSize:12,fontWeight:"bold",color:selectedFunction&&selectedFunction.id_clave===item.id_clave?"#fff":colors.secondary}}>{item.estado+" - "+item.ciudad}</span><br></br>
                                </div>
                            </div>
                        ))
                    } 
                </div> : 
                <Fragment>
                    <div className="d-flex justify-content-center mt-4">
                        <span style={fontStyle}>Parece que no hay eventos cercanos.</span>
                    </div>
                    <div className="d-flex justify-content-center mt-1">
                        <FaRegSadCry style={{fontSize:40,color:"#fff"}} />
                    </div>
                </Fragment>
            }
        </Fragment>
    )
}
export default Component;