import React from "react";
import {AreaSelectorProvider,useAreaSelector} from "./Context";
import {useUser} from "../../../../../Context/User";
import { Fade } from "@mui/material";
import Content from "./Content";
import AloneLoader from "../../../AloneLoader";

const AreaSelector = (props)=>{
    const {selectedDate} = useUser();

    return(
        <>
            <Fade in={true} timeout={800}>
                <div>
                    <AreaSelectorProvider id_clave={selectedDate}>
                        <AreaSelectorTunnel Content={()=><Content />} />
                    </AreaSelectorProvider>
                </div>
            </Fade>
        </>
    )
}
export default AreaSelector;

const AreaSelectorTunnel = (props)=>{
    const {Content} = props;
    const {isLoading} = useAreaSelector();
    return(
        <>
            {
                isLoading ? <AloneLoader isVisible={true} size={100} /> : <Content />
            }
        </>
    )
}