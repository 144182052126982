import { useHomeContext } from "../../store";
import NoEvents from "./NoEvents";
import Body from "../../../../../Components/Elements/LeftBar/Body";
const EventSelector = ()=>{
    const {HomeData} = useHomeContext();
    const {events} = HomeData;
    //console.log("HomeData in EventSelector",HomeData)
    if(events.length===0)return <NoEvents />;
    //if(events.length>0)return <EventsController />;
    if(events.length>0)return <Body />;
    return null;
}
export default EventSelector;