import { Fragment } from "react";
import EventSelector from "./EventSelector";
import ModalFunctions from "./ModalFunctions";
import Header from "../../../../Components/Elements/Header";
const Body = ()=>{
    return(
        <Fragment>
            <Header />
            <EventSelector />
            <ModalFunctions />
        </Fragment>
    )
}
export default Body;