import axios from "axios";
import { GetBackend } from "./getBackend";
import { GetFrontend } from "./getFrontend";
import { getPKStripe } from "./getPKStripe";

export const BACKEND = GetBackend();
export const FRONTEND = GetFrontend();
export const PKSTRIPE = getPKStripe();
//export const BACKEND = "https://duermase.net/";
//export const BACKEND = "http://192.168.3.9:8081/";
//export const BACKEND = process.env.REACT_APP_DEV === "true" ? "https://develop.duermase.net/" : "https://develop.duermase.net/";
//export const FRONTEND = process.env.REACT_APP_DEV === "true" ? "https://develop.duermase.net/" : "https://develop.duermase.net/";
//export const BACKEND = "/";


//export const BACKEND = "https://software-tickets.herokuapp.com/";
//export const BACKEND = "https://jm-shop-test.herokuapp.com/";
//export const BACKEND = "https://tickets.duermase.net/";

//DirectoryIndex ""
//
//RewriteEngine On
//RewriteCond %{REQUEST_URI} ^.*/index.*
//RewriteRule ^(.*)$ http://localhost:8081/ [P,L]
//
//RewriteRule ^$ http://127.0.0.1:8081/ [P,L]
//RewriteCond %{REQUEST_FILENAME} !-f
//RewriteCond %{REQUEST_FILENAME} !-d
//RewriteRule ^(.*)$ http://localhost:8081/$1 [P,L]

export const colors = {
    primary: "#1e1e1e",
    secondary: "#bc0303",
    bgModals:"#2b2b35",
    bgDark:"#32323b",
    bgGrayDark:"#32323b",
    gray:"#c1c1c1",
    white:"#fff",
    buttons:{
      success:"linear-gradient(90deg, rgba(242, 5, 5, 0.7) 0%, rgba(242, 5, 5, 1) 100%)",
      danger:"linear-gradient(90deg, rgba(161, 161, 161, 0.7) 0%, rgba(161, 161, 161, 1) 100%)",
      info:"linear-gradient(90deg, rgba(188, 3, 3, 0.7) 0%, rgba(188, 3, 3, 1) 100%)"
    }
}
export const isWeb = ()=>{
    if(window.screen.width>=600){
        return true;
    }else{
        return false;
    }
}
export const encryptText = (text)=>{
  let texto = text.toString();
  let abc = ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","ñ","o","p","q","r","s","t","u","v","w","x","y","z","0","1","2","3","4","5","6","7","8","9"," "];
  let cba = ["e","v","z","1","a","l","9","h"," ","p","5","t","c","8","j","3","r","u","2","f","ñ","x","d","6","b","y","7","i","4","g","0","m","s","n","q","k","w","o"];
  let arrayString = Array.from(texto);
  let newString = "";
  for (var k = 0; k < arrayString.length; k++) {
    for (var i = 0; i < abc.length; i++) {
      if(arrayString[k].toLowerCase()===abc[i]){
        newString += cba[i];
      }
    }
  }
  return newString;
}
export const errorTagStyle = {
  padding:20,
  borderRadius:15,
  borderStyle:"solid",
  borderWidth:2,
  borderColor:colors.secondary,
  width:"100%"
}
  export const REQUEST = ({url,postData,handleToggleLoad,time})=>{
  return new Promise((res,rej)=>{
    if(handleToggleLoad){
      handleToggleLoad(true);
    }
    //console.log("Consultando:",BACKEND+url);
    (async()=>{
      let to = BACKEND+(url||"");
      console.log("Consultando:",to);
      if(postData && postData.user){
        postData.user = null;
      }
      let r = await axios.post(to,postData).catch(e=>{
        //console.log("entro al catch",e);
        //console.log("TO:",to)
        //console.log("PostData:",postData)
        res({error:"Error al conectar al servidor"})
        if(handleToggleLoad){
          handleToggleLoad(false);
        }
      })
      if(r){
        setTimeout(()=>{
          if(r.data.error){
            res({error:r.data.error})
          }else{
            res({result:r.data.result,error:null});
          }
          if(handleToggleLoad){
            handleToggleLoad(false);
          }
        },time||500)
      }
    })()
  })
}