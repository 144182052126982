import React from 'react';
import IconButton from '../../../../../IconButton';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { FRONTEND } from '../../../../../../../Utils/Constants';
import { Toast } from '../../../../../../../Utils/Toast';
const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
        }

        const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
            return_url: `${FRONTEND}payment-proccess`,
        },
        });

        if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
            Toast(result.error.message,"error");
        } else {
            //console.log("STRIPE SUCCESS RESULT:",result);
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <div style={{
                display:"flex",
                justifyContent:"center",
                marginTop:20
            }}>
                <IconButton 
                    title="Pagar"
                    disabled={!stripe}
                />
            </div>
        </form>
    );
};

export default CheckoutForm;