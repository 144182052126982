import React, {Fragment} from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useScreen } from "../../Context/Screen/store";
import { colors } from "../../Utils/Constants";
import TicketsViewer from "./TicketsViewer";

const Content = ()=>{

    return(
        <Fragment>
            <StatusViewer />
            <TicketsViewer />
        </Fragment>
    )
}
const StatusViewer = ()=>{
    const {screenData} = useScreen();
    const {status} = screenData;
    return(
        <Fragment>
            <Container>
                <Row style={{backgroundColor:colors.bgDark, padding:"20px"}}>
                    <Col xs="12" md="6">
                        <span style={{color:colors.gray}}>
                            ESTADO DE PAGO:
                        </span>
                    </Col>
                    <Col xs="12" md="6">
                        <div className="d-flex justify-content-end">
                            <span style={{
                                color:status.status_id===2?"green":"red",
                                fontWeight:"bold",
                                textAlign:"right"
                            }}>
                                {status.status_name}
                            </span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
export default Content;