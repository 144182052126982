/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useMercadopago } from 'react-sdk-mercadopago';
import { useConfirmation } from "../Store";

const MercadoPagoForm = ()=>{
    const {mpParams} = useConfirmation();
    const {APIKEY_PUBLIC,PREFERENCE} = mpParams;
    const mercadopago = useMercadopago.v2(APIKEY_PUBLIC, {
        locale: 'es-MX'
    });
    React.useEffect(() => {
        if (mercadopago) {
            mercadopago.checkout({
                preference: {
                    id: PREFERENCE.IDMP
                },
                render: {
                    container: '.mercadopago-container',
                    label: 'Procesar Pago.',
                }
            })
        }
    }, [mercadopago])
    return (
        <div className="d-flex justify-content-center">
            <div class="mercadopago-container" />
            <IntervalComponent seconds={3} />
        </div>
    )
}
const IntervalComponent = (props)=>{
    const {seconds} = props;
    const {refreshMpEstatus} = useConfirmation();
    React.useEffect(() => {
        const interval = setInterval(() => {
            refreshMpEstatus()
        }, seconds*1000);
        return () => clearInterval(interval);
    }, []);
    return "";
}
export default MercadoPagoForm;