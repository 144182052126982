import {Fragment} from "react";
import {MenuList,Menu,MenuItem,ListItemIcon,ListItemText} from "@mui/material";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import { colors } from "../../../Utils/Constants";
import { useUser } from "../../../Context/User";
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from "react-avatar";
const Header = ()=>{
    const {
        user,
        isOpenedMenuList,
        menuListOption,
        setMenuListOption
    } = useUser();
    return(
        <div style={{
            display:"flex",
            flexDirection:"row",
            width:"100%",
            padding:3,
            backgroundColor:colors.bgGrayDark,
        }}>
            <div style={{
                display:"flex",
                width:"30%",
                padding:3,
            }}>
                <RightSide />
            </div>
            <div style={{
                display:"flex",
                width:"100%",
                justifyContent:"flex-end",
            }}>
                <LeftSide />
                <Menu
                    id="basic-menu"
                    anchorEl={menuListOption}
                    open={isOpenedMenuList}
                    onClose={()=>setMenuListOption(false)}
                    sx={{
                        '& .MuiPaper-root': {
                            width: "100%",
                            backgroundColor: colors.bgGrayDark,
                            borderStyle: "solid",
                            borderColor: colors.primary
                        },
                        width: "100%",
                        p: 0
                    }}
                >
                    <MenuList sx={{minWidth:200}}>
                        <RightMenuContent user={user}/>
                    </MenuList>
                </Menu>
            </div>
        </div>
    )
}
const RightMenuContent = ()=>{
    const {
        user,
        logout,
        setMenuListOption
    } = useUser();
    const onPressLogout = ()=>{
        logout();
        setMenuListOption(false);
    }
    if(user){
        return(
            <Fragment>
                <MenuItem onClick={onPressLogout}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" style={{color:colors.primary}} />
                    </ListItemIcon>
                    <ListItemText style={{color:colors.secondary}}>Salir</ListItemText>
                </MenuItem>
            </Fragment>
        )
    }
    return (
    
        <MenuItem>
            <ListItemIcon>  
                <LogoutIcon fontSize="small" style={{color:colors.primary}} />
            </ListItemIcon>
            <ListItemText style={{color:colors.secondary}}>Salir</ListItemText>
            <Avatar name={`INVITADO`} round size={30} />
        </MenuItem>
    )
}
export default Header;