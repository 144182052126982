/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState,useMemo,useEffect} from "react";
import {REQUEST} from "../../../../../Utils/Constants";
import {Toast} from "../../../../../Utils/Toast";


const AreaSelectorContext = React.createContext();

export const AreaSelectorProvider = (props)=>{
    const {id_clave} = props;
    const [areas,setAreas] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const handleLoading = (param)=>setIsLoading(param);

    const getData = async()=>{
        handleLoading(true);
        let postData = {type:"getAreas",id_clave};
        let r = await REQUEST({url:"",postData}).catch(e=>{
          Toast("Error al conectar al servidor","error");
          handleLoading(false);
        })
        if(r){
          if(r.error){
            Toast(r.error,"error");
          }else{
            setAreas(r.result.areas)
          }
        }
        handleLoading(false);
    }

    useEffect(()=>{
        getData();
    },[id_clave])
    const value = useMemo(()=>{
        return({
            areas,
            getData,
            isLoading
        })
    },[areas,isLoading])
    return <AreaSelectorContext.Provider value={value} {...props}/>
}


export const useAreaSelector = ()=>{
  return React.useContext(AreaSelectorContext);
}
