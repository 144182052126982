import React from "react";
import {Container,Row,Col} from "react-bootstrap";
import {useTickets} from "./Context";
import { colors, REQUEST } from "../../../../../Utils/Constants";
import {useUser} from "../../../../../Context/User";
import TimerComponent from "./TimerComponent";
import { Toast } from "../../../../../Utils/Toast";
const CardList = (props)=>{
    const {pagado} = props;
    const {handleLoading} = useTickets();
    const {resetValues,user} = useUser();
    const updateUserTickets = async()=>{
        if(!pagado){
            let r = await REQUEST({
                url:"",
                postData:{
                    type:"updateUserTickets",
                    user:user.id_usuario
                },
                handleToggleLoad:handleLoading
            })
            if(r){
                if(r.error){
                    Toast(r.error,"error");
                }else{
                    Toast("Lo sentimos, el tiempo límite para la compra de su boleto ha expirado. Intentelo nuevamente.","error");
                    resetValues();
                }
            }
        }
    }
    return(
        <Container>
            <Row>
                <TicketsDataViewer />
                {/*<PayMethodViewer 
                    payMethod={payMethod}
                    SAVEPAYMENT={SAVEPAYMENT}
                    setSAVEPAYMENT={setSAVEPAYMENT}
                />*/}
                <TimerViewer 
                    updateUserTickets={updateUserTickets}
                />
            </Row>
        </Container>
    )
}
const TicketsDataViewer = ()=>{
    const {tickets} = useUser();
    const leftDiv = {
        width:"100%",
        borderRadius:10,
        borderStyle:"solid",
        borderWidth:2,
        borderColor:colors.bgGrayDark,
        padding:10
    }
    const returnFilas = (t)=>{
        let newFilas = [];
        for (let i = 0; i < t.length; i++) {
            const item = t[i];
            if(i===0){
                newFilas.push(item.rowName);
            }else{
                let check = newFilas.filter(el=>el===item.rowName);
                if(check.length===0){
                    newFilas.push(item.rowName);
                }
            }
        }
        let newFilas2 = newFilas.filter((el,i)=>i<=2);
        let str = "";
        newFilas2.forEach((item,index)=>{
            str += item;
            if(index!==newFilas2.length-1){
                str += "-"
            }
        })
        let points = newFilas.length >= 3 ? "..." : "";
        return str + points ;
    } 
    const rtnStringTickets = (t)=>{
        let string = "";
        for (let i = 0; i < t.length; i++) {
            const item = t[i];
            if(item.estado===1&&i<2){
                string += item.butaca;
                if(i!==t.length-1&&i<2){
                    string += "-"
                }
            }
        }
        if(tickets.length>=3){
            string += "...";
        }
        return string;
    }
    return(
        <Col xs="12" md={"8"} className="mt-1">
            <div style={leftDiv}>
                <Row>
                    <Col xs="6">
                        <div style={{textAlign:"center"}}>
                            <span style={{color:colors.gray}}>
                                Boletos:
                            </span>
                        </div>
                    </Col>
                    <Col xs="6">
                        <div style={{textAlign:"center"}}>
                            <span style={{color:colors.gray}}>
                                {tickets.length}
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <div style={{textAlign:"center"}}>
                            <span style={{color:colors.gray}}>
                                {
                                    tickets.length > 1 ? "Asientos:" : "Asiento:"
                                }
                            </span>
                        </div>
                    </Col>
                    <Col xs="6">
                        <div style={{textAlign:"center"}}>
                            <span style={{color:colors.gray}}>
                                {rtnStringTickets(tickets)}
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <div style={{textAlign:"center"}}>
                            <span style={{color:colors.gray}}>
                                Fila:
                            </span>
                        </div>
                    </Col>
                    <Col xs="6">
                        <div style={{textAlign:"center"}}>
                            <span style={{color:colors.gray}}>
                                {returnFilas(tickets)}
                            </span>
                        </div>
                    </Col>
                </Row>
            </div>
        </Col>

    )
}
/*const CardForm = ({payMethod,SAVEPAYMENT,setSAVEPAYMENT})=>{
    const {CVC,setCVC,NEXT,PREV,NUMBER,setNUMBER,CARDNAME,setCARDNAME,EXPMONTH,setEXPMONTH,EXPYEAR,setEXPYEAR,DIR,setDIR} = useTickets();
    const years_items = expiry_year_items(get_ini_year(),15);
    const months_items = expiry_month_items();
    const {data} = useTickets();
    const {paymethods} = data;
    const getPayMethodIndex = ()=>{
        let index;
        for (let i = 0; i < paymethods.length; i++) {
            const element = paymethods[i];
            if(element.id_metodo===payMethod.id_metodo){
                index = i;
            }
        }
        return index;
    }
    const index = getPayMethodIndex();
    const CHECKNUMBER = ()=>{
        if(!NUMBER||isNaN(NUMBER)){
            return "Formato de tarjeta inválido"
        }else{
            if(NUMBER.length===16){
                return null
            }else{
                return "Debe contener 16 números."
            }
        }
        
    }
    const handleToggleSave = ()=>{
        setSAVEPAYMENT(!SAVEPAYMENT);
    }

    return(
        <Col xs="12" md="4" className="mt-1">
            {
                payMethod && payMethod.numero ? 
                <Row>
                    <Col xs="2">
                        <div style={{display:"flex",justifyContent:"center",marginTop:40}}>
                            <FaRegArrowAltCircleLeft onClick={PREV} style={{fontSize:25,color:index===0?"#c1c1c1":colors.white,cursor:index===0?"no-drop":"pointer"}} />
                        </div>
                    </Col>
                    <Col xs="8">
                        <div className="hipnoBackground" style={{width:"100%",height:100,borderRadius:10}}>
                            <div style={{width:"100%",padding:10,textAlign:"center"}}>
                                <span style={{fontSize:15,fontWeight:"bold",color:"#fff"}}>
                                    {payMethod.nombre}
                                </span><br />
                                <span style={{fontSize:15,fontWeight:"bold",color:"#fff"}}>
                                    {payMethod.numero}
                                </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div style={{maxWidth:300,marginTop:10}}>
                                <Row>
                                    <Col xs="12">
                                        <Input value={DIR} placeholder="Dirección de la tarjeta" onChange={setDIR} errorMsg={!DIR?"Especifique la dirección de la tarjeta, si no coincide con el banco el pago se rechazará":null}/>
                                    </Col>
                                    <Col xs="12">
                                        <Input value={CVC} placeholder="CVC-cvc" onChange={setCVC} errorMsg={!CVC||isNaN(CVC)?"Formato de CVC inválido":null}/>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col xs="2">
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:40}}>
                            <FaRegArrowAltCircleRight onClick={NEXT} style={{fontSize:25,color:index===paymethods.length-1?"#c1c1c1":colors.secondary,cursor:index===paymethods.length-1?"no-drop":"pointer"}} />
                        </div>
                    </Col>
                </Row>
                : 
                <Row>
                    <Col xs="12">
                        <Input value={NUMBER} placeholder="Número de tarjeta" onChange={setNUMBER} errorMsg={CHECKNUMBER()}/>
                    </Col>
                    <Col xs="12" style={{marginTop:10}}>
                        <Input value={CARDNAME} placeholder="Nombre en tarjeta" onChange={setCARDNAME} errorMsg={!CARDNAME||CARDNAME.length<10?"Inserte el nombre completo del titular de la tarjeta":null}/>
                    </Col>
                    <Col xs="12">
                        <Input value={DIR} placeholder="Dirección de la tarjeta" onChange={setDIR} errorMsg={!DIR?"Especifique la dirección de la tarjeta, si no coincide con el banco el pago se rechazará":null}/>
                    </Col>
                    <Col xs="4">
                        <div>
                            <p style={{fontSize:10,fontWeight:"bold"}}>
                            Mes
                            </p>
                        </div>
                        <select className="form-control" style={selectStyle} value={EXPMONTH} onChange={e=>setEXPMONTH(e.target.value)}>
                            {
                            months_items.map(item=>(
                                <option value={item} key={item}>
                                {item}
                                </option>
                            ))
                            }
                        </select>
                    </Col>
                    <Col xs="4">
                        <div>
                            <p style={{fontSize:10,fontWeight:"bold",textAlign:"center"}}>
                            Año
                            </p>
                        </div>
                        <select className="form-control" style={selectStyle} value={EXPYEAR} onChange={e=>setEXPYEAR(e.target.value)}>
                            {
                            years_items.map(item=>(
                                <option value={item} key={item}>
                                {item}
                                </option>
                            ))
                            }
                        </select>
                    </Col>
                    <Col xs="4" style={{marginTop:20}}>
                        <Input value={CVC} placeholder="CVC" onChange={setCVC} errorMsg={!CVC||isNaN(CVC)?"Formato de CVC inválido":null}/>
                    </Col>
                    <Col xs="2">
                        {
                            SAVEPAYMENT ? 
                            <FaCheckCircle onClick={handleToggleSave} style={{fontSize:25,color:colors.secondary,cursor:"pointer"}} /> 
                            :
                            <FaCircle onClick={handleToggleSave} style={{fontSize:25,color:colors.gray,cursor:"pointer"}} />
                        }
                    </Col>
                    <Col xs="10">
                        <span onClick={handleToggleSave} style={{fontWeight:"bold",fontSize:12,color:SAVEPAYMENT?colors.secondary:colors.gray,textAlign:"center"}} className="alineado">
                            Guardar método de pago para futuras compras.
                        </span>
                    </Col>
                </Row>
            }
            
        </Col>
        
    )
}*/
const TimerViewer = ({updateUserTickets})=>{
    const {data} = useTickets();
    const {seconds,API_TYPE} = data;
    const time = new Date();
    time.setSeconds(time.getSeconds() + seconds); // 10 minutes timer

    return(
        <Col xs="12" md={API_TYPE===1?"4":"4"} className="mt-1">
            <div className="d-flex justify-content-center">
                <TimerComponent expiryTimestamp={time} callback={updateUserTickets}/>
            </div>
        </Col>
    )
}
export default CardList;
/*const expiry_month_items = ()=>{
    return [
      "01","02","03","04","05","06","07","08","09","10","11","12"
    ]
}
const expiry_year_items = (ini_year,times)=>{
    let ini = parseInt(ini_year);
    let items = [];
    for (var i = 0; i < (times); i++) {
      items.push(returnStringNum(ini+(i)))
    }
    return items;
}
const get_ini_year = ()=>{
    let date = new Date();
    let year =  date.getFullYear().toString();
    return year.slice(2,4);
}
const returnStringNum = (num)=>{
    if(num>=10){
      return num.toString();
    }else{
      return "0"+num;
    }
}
const selectStyle = {
    width:"100%",
    height: 40,
    borderRadius:5,
    borderStyle:"solid",
    borderWidth:1,
    borderColor:colors.primary,
    backgroundColor:colors.secondary,
    color:"#fff",
    fontWeight:"bold",
    fontSize:20,
    marginTop:-10
  }*/