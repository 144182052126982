import React from "react";
import { useUser } from "../User";
import ScreenLoader from "./ScreenLoader";
import { ScreenProvider, useScreen } from "./store";
import ErrorContainer from "../../Components/Elements/ErrorContainer";
import LoginComponent from "../../Components/Elements/LoginComponent";
import { Card } from "react-bootstrap";
import Header from "../../Components/Elements/Header";
import { colors } from "../../Utils/Constants";

const SCREEN = (props)=>{
    const {user} = useUser();
    if(user===null&&props.SCREEN_NEED_USER){
        return <ScreenLoader visible={true} />
    }
    return(
        <ScreenProvider {...props}>
            <SCREENTUNNEL>
                {props.children||"Contenido no especificado"}
            </SCREENTUNNEL>
        </ScreenProvider>
    )
}
export default SCREEN;

const SCREENTUNNEL = (props)=>{
    const {screenData,screenLoad,screenError,SCREEN_NEED_USER,url} = useScreen();
    const {user} = useUser();
    if(screenLoad){
        return <ScreenLoader visible={true} />
    }
    if(screenError){
        return <ErrorContainer error={screenError} style={{marginTop:20,backgroundColor:"#fff"}} />
    }
    if(!user&&SCREEN_NEED_USER&&!screenLoad){
        return <LoginComponent rightPanel />
    }
    if((!screenData)&&(url!==null)&&(!screenLoad)){
        return "espere..";
    }
    return(
        <SCREENMODEL>
            {props.children||"Contenido no especificado"}
        </SCREENMODEL>
    )
}
const SCREENMODEL = (props)=>{

    return(
        <div style={{width:"100%"}}>
            <Header />
            <div style={{padding:10,width:"100%"}}>
                <Card style={{display:"flex",width:"100%",height:"100%",padding:10,backgroundColor:colors.primary}}>
                    {props.children||"Contenido no especificado"}
                </Card>
                <div style={{height:100}}></div>
            </div>
        </div>
    )
}